import {StorageInterface, StorageOptions} from './index';
import {
  Clock,
  BaseStorageWithManualExpiry,
} from './baseStorageWithManualExpiry';
import {debug} from '../utils/log';

// don't interfere with storage of the host app
const STORAGE_PREFIX = 'npotag_';
const TAG = 'LocalStorage';

export class LocalStorage extends BaseStorageWithManualExpiry implements StorageInterface {
  /**
   *
   * @param clock for testing purposes, a function that returns the current time as Date
   */
  constructor(clock?: Clock) {
    super(TAG, clock);
  }

  get(key: string): string | undefined {
    try {
      const rawItem = localStorage.getItem(STORAGE_PREFIX + key);
      if (!rawItem) {
        return undefined;
      }

      const value = this.getValueFromRaw(rawItem);
      if (typeof value === 'undefined') {
        debug(this.tag, `Expiring storage item ${key}`);
        this.remove(key);
      }
      return value;
    } catch (e) {
      // maybe unparseable ?
      debug(
        this.tag,
        `Error retrieving item ${STORAGE_PREFIX + key} from storage: ${e}`
      );
      return undefined;
    }
  }

  set(key: string, value: string, options?: StorageOptions): void {
    const item = this.create(value, options);
    localStorage.setItem(STORAGE_PREFIX + key, JSON.stringify(item));
  }

  remove(key: string) {
    debug(this.tag, `removing item ${STORAGE_PREFIX + key}`);
    localStorage.removeItem(STORAGE_PREFIX + key);
  }
}
