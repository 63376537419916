import {StorageInterface, StorageOptions} from './index';
import {debug} from '../utils/log';

const TAG = 'LocalStorage';

interface StoredItem {
  value: string;
  expiresAt?: number;
}

// don't interfere with storage of the host app
const STORAGE_PREFIX = 'npotag_';

export type Clock = () => Date;

export class LocalStorage implements StorageInterface {
  private clock: Clock;

  /**
   *
   * @param clock for testing purposes, a function that returns the current time as Date
   */
  constructor(clock?: Clock) {
    this.clock = clock || (() => new Date());
  }

  get(key: string): string | undefined {
    try {
      const rawItem = localStorage.getItem(STORAGE_PREFIX + key);
      if (!rawItem) {
        return undefined;
      }

      const item = JSON.parse(rawItem) as StoredItem;
      const now = this.clock();
      if (item.expiresAt && item.expiresAt < now.getTime()) {
        debug(TAG, `Expiring storage item ${key}`);
        localStorage.removeItem(STORAGE_PREFIX + key);
        return undefined;
      }
      return item.value;
    } catch (e) {
      // maybe unparseable ?
      debug(TAG, `Error retrieving item ${key} from storage: ${e}`);
      return undefined;
    }
  }

  set(key: string, value: string, options?: StorageOptions): void {
    let expiresAt: number | undefined = undefined;
    if (options?.expires) {
      // options.expires is number of days
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      expiresAt = this.clock().getTime() + options.expires * millisecondsPerDay;
    }
    const item: StoredItem = {
      value,
      expiresAt,
    };
    localStorage.setItem(STORAGE_PREFIX + key, JSON.stringify(item));
  }

  remove(key: string) {
    localStorage.removeItem(STORAGE_PREFIX + key);
  }
}
