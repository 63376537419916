import {LocalStorage} from './localStorage';
import {CookieStorage} from './cookieStorage';
import {MemoryStorage} from './memoryStorage';
import {debug, warn} from '../utils/log';

const TAG = 'Storage';

export interface StorageOptions {
  expires: number; // in days
}

// note: 'Storage' is already claimed by the Web Storage API
export interface StorageInterface {
  get(key: string): string | undefined;
  set(key: string, value: string, options?: StorageOptions): void;
  remove(key: string): void;
}

let storageInstance: StorageInterface | undefined = undefined;

export function createStorage(): StorageInterface {
  let storage: StorageInterface | undefined = undefined;

  if (storageInstance) {
    return storageInstance;
  }

  const testKey = 'npo_tag_storage_tester';
  const magicValue = (Math.random() * 20428572).toFixed(0);

  // See if localStorage works
  try {
    // note: just *accessing* localStorage will throw an exception in firefox when local storage is blocked
    if (window && window.localStorage) {
      // test it - it appears some broken set-top boxes *seem* to support local storage, but actually don't\
      storage = new LocalStorage();
      storage.set(testKey, magicValue, {expires: 1});
      const retrieved = storage.get(testKey);
      storage.remove(testKey);

      if (retrieved !== magicValue) {
        // We *seemed* to have local storage, but it didn't actually retrieve a value?
        // TODO: send event to topspin containing the platform we're running on
        throw new Error('magic value mismatch');
      }
      // OK!
      storageInstance = storage;
    }
  } catch (e) {
    warn(TAG, 'Not using local storage because: ', e);
  }

  if (!storageInstance) {
    try {
      // fall back to cookie storage
      debug(TAG, 'Try cookie storage');
      storage = new CookieStorage();
      storage.set(testKey, magicValue, {expires: 1});
      const retrieved = storage.get(testKey);
      storage.remove(testKey);

      if (retrieved !== magicValue) {
        throw new Error('magic value mismatch');
      }
      // OK!
      storageInstance = storage;
    } catch (e) {
      warn(TAG, 'Not using cookie storage because: ', e);
    }
  }

  if (!storageInstance) {
    debug(TAG, 'Falling back to memory storage');
    storageInstance = new MemoryStorage();
  }

  return storageInstance;
}

export const getStorage: () => StorageInterface = () => {
  if (!storageInstance) {
    warn(TAG, 'Storage used before being created?');
    storageInstance = createStorage();
  }
  return storageInstance;
};
