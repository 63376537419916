import {StorageInterface, StorageOptions} from './index';
import {debug} from '../utils/log';
import {
  Clock,
  BaseStorageWithManualExpiry,
} from './baseStorageWithManualExpiry';

const TAG = 'MemoryStorage';

export class MemoryStorage extends BaseStorageWithManualExpiry implements StorageInterface {
  private readonly store: {[key: string]: string};

  constructor(clock?: Clock) {
    super(TAG, clock);
    this.store = {};
  }

  get(key: string): string | undefined {
    try {
      const rawItem = this.store[key];
      if (!rawItem) {
        return undefined;
      }
      const value = this.getValueFromRaw(rawItem);
      if (typeof value === 'undefined') {
        debug(this.tag, `Expiring storage item ${key}`);
        this.remove(key);
      }
      return value;
    } catch (e) {
      // maybe unparseable ?
      debug(this.tag, `Error retrieving item ${key} from storage: ${e}`);
      return undefined;
    }
  }

  set(key: string, value: string, options?: StorageOptions): void {
    const item = this.create(value, options);
    this.store[key] = JSON.stringify(item);
  }

  remove(key: string) {
    delete this.store[key];
  }
}
