import {StorageOptions} from './index';

export type Clock = () => Date;

interface StoredItem {
  value: string;
  expiresAt?: number;
}

export abstract class BaseStorageWithManualExpiry {
  protected clock: Clock;
  protected tag: string;

  /**
   *
   * @param clock for testing purposes, a function that returns the current time as Date
   * @param tag for logging
   */
  constructor(tag: string, clock?: Clock) {
    this.clock = clock || (() => new Date());
    this.tag = tag;
  }

  protected getValueFromRaw(rawItem: string): string | undefined {
    const item = JSON.parse(rawItem) as StoredItem;
    const now = this.clock();
    if (item.expiresAt && item.expiresAt < now.getTime()) {
      return undefined;
    }
    return item.value;
  }

  protected create(value: string, options?: StorageOptions): StoredItem {
    let expiresAt: number | undefined = undefined;
    if (options?.expires) {
      // options.expires is number of days
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      expiresAt = this.clock().getTime() + options.expires * millisecondsPerDay;
    }
    return {
      value,
      expiresAt,
    };
  }
}
